.video-iframe iframe {
    max-width: 100% !important;
}

.employee-img {
    max-width: 100px;
}

.employee-list-img {
    max-width: 100%;
}

.emp-page-logo {
    width: 300px;
}

.card-body .table-responsive .btn-group .dropdown-menu {
    max-height: 200px;
    overflow: auto;
}

.modal-dialog .modal-body .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 80px;
    overflow: auto;
}

.text-center {
    text-align: center !important;
}

.view-page form label, 
.view-page .form-group label {
    color: #75787d !important;
    font-size: .75rem !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    font-weight: 500 !important;
}

.view-page .form-group p {
    color: rgba(0,0,0,.87) !important;
}

.rates-view-row {
    margin: 0;
    padding: 0 10px;
    margin: 10px 0;
    border: 1px dashed grey;
    border-radius: 10px;
}

.horizontal-menu.navbar-static .header-navbar.navbar-sticky {
    border-bottom: 1px solid #E0E0E0;
}
.navbar-nav .dropdown-menu {
    top: auto;
    left: auto;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    padding-right: 0;
    background-image: none;
}
.ft-user:before {
	width: 35px;
	height: 35px;
	padding-top: 5px;
}

.is-invalid .ng-select-container {
    border-color: #FF586B;
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control-file.is-invalid {
    border: 1px solid;
    border-color: #FF586B;
    background-repeat: no-repeat;
}

.assessment-section-heading {
    background: #800080 !important;
    color: white !important;
    border-bottom: 1px solid #800080 !important;
}

.text-uppercase {
    text-transform: uppercase;
}
.template-img {
    max-width: 100%;
    height: auto;
}
.logo-img-style {
    max-width: 50px;
}

.padding-0 {
  padding: 0;
}

.border {
  border: 1px solid black;
}

.sidebar-logo-img-style {
    max-width: 30px;
}

.card-body .table-responsive tbody tr .word-wrap {
    word-break: break-word !important;
}

.sk-wave .sk-rect {
    background-color: #000 !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}
.margin-bottom-0 {
    margin-bottom: 0 !important;
}
.padding-left-20 {
    padding-left: 20px !important;
}

.notice {
    color: #B94A48 !important;
}


/* Email template layout Style */
.panel-template-style .panel-body img {
    max-width: 100%;
}
.panel-template-style:hover {
    border-color: #166dba;
}
/* End of Email template layout Style */


/* Error message css */
.text-error {
    font-size: 10px;
    /*background-color: #FDF7F7;
    border-left: 3px solid #EED3D7;
    padding: 10px;*/
    display: block;
    color: #B94A48 !important;
    margin-top: 8px !important;
    margin-bottom: 8px;
    text-align: left
}

/* End of Error message css */


/* File uploader style */
input[type="file"] {
    background: gainsboro !important;
}
/* End of File uploader style */

.card table .dropdown-toggle::after {
    display: none;
}
.card table .dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
}

/* Font awesome icon style */
.card table .fa {
    padding-right: 10px !important;
}

.card table .fa-ellipsis-h {
    font-size: 32px;
}

.card .fa-users {
    font-size: 20px;
    color: green;
}

.card .fa-edit {
    font-size: 22px;
    color: orange;
}

.card .fa-question-circle {
    font-size: 24px;
    color: #975AFF;
}

.card .fa-archive {
    font-size: 20px;
    color: green;
}

.card .fa-eye {
    font-size: 22px;
    color: #1DC7EA !important;
}

.card .fa-plus {
    font-size: 22px;
    color: #1DC7EA !important;
}

.card .fa-trash {
    font-size: 20px;
    color: red;
}

.card .fa-history {
    font-size: 20px;
    color: green;
}

.card .fa-tasks {
    font-size: 20px;
    color: blue;
}

.card .fa-check {
    font-size: 20px;
    color: green;
}

.card .fa-file-text {
    font-size: 20px;
    color: #009DA0;
}

.card .fa-undo {
    font-size: 20px;
    color: green;
}

/* End of Font awesome icon style */


/* Selectize Style */
.is-invalid .selectize-input {
    border-color: red !important;
}

.selectize-input {
    padding: 0.375rem 0.75rem !important;
    border-radius: 0.25rem !important;
}

.selectize-input .item {
    font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #75787d !important;
}

/* End Selectize Style */


/* Datatable Style */
/*.dataTables_wrapper {
  min-height: 400px !important;
}*/

.dataTables_length {
    width: 20% !important;
    display: inline-block !important;
}

.dt-buttons {
    width: 55% !important;
    display: inline-block !important;
}

.dataTables_filter {
    width: 25% !important;
    display: inline-block !important;
}

.dataTables_filter {}

.dataTables_empty {
    display: none !important;
}

@media only screen and (max-width: 780px) {
    .dataTables_length {
        width: 50% !important;
        display: inline-block !important;
    }

    .dt-buttons {
        width: 100% !important;
        display: block !important;
        text-align: center !important;
    }

    .dataTables_filter {
        width: 50% !important;
        display: inline-block !important;
    }
}

/* End Datatable Style */


/* Accordionn Style */
.accordion .card-header .btn[class*="btn-"] {
    width: 100% !important;
    text-align: left !important;
    /*margin-bottom: 10px !important;*/
    padding: 0 !important;
}

.accordion .card-header .btn-link:hover {
    text-decoration: none !important;
}

.accordion .card-header {
    padding: 0 !important;
}

.accordion .card-header .btn[class*="btn-"] {
    margin-bottom: 0px;
}
.accordion .card:not(:last-of-type) .card-header {
    border-bottom: none;
}
.accordion .card .card-header button {
    padding: 10px;
}

/* End Accordionn Style */


/* Named Signature Style */

/* latin */
@font-face {
  font-family: 'Homemade Apple';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Homemade Apple Regular'), local('HomemadeApple-Regular'), url(https://fonts.gstatic.com/s/homemadeapple/v10/Qw3EZQFXECDrI2q789EKQZJob0x6XHgOiJM6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.named-sign-font {
    font: 400 130px/0.8 'Homemade Apple';
}
input.named-sign-font {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
}
p.named-sign-font {
    font-size: 25px !important;  
    padding-top: 20px !important;
}


/* End Named Signature Style */


.ngb-tp-input-container .btn,
.ngb-tp-meridian .btn {
  margin-bottom: 0 !important;
}